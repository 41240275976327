import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout/Layout'
import HeroSection from '../sections/home/HeroSection'
import WhoSection from '../sections/home/WhoSection'
import ProjectsSection from '../sections/home/ProjectsSection'
import HobbiesSection from '../sections/home/HobbiesSection'

const Home = () => {
  return (
    <Layout pageName="home">
      <Wrapper>
        <HeroSection />
        <WhoSection />
        <ProjectsSection />
        <HobbiesSection />
      </Wrapper>
    </Layout>
  )
}

export default Home

const Wrapper = styled.div``