import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint } from '../../configs/utilities'
import { SectionTitle, SectionDescription } from '../common/typography/Typography'
import HomeCardComponent from '../common/cards/HomeCardComponent'

const HomeCardsSectionComponent = ({ intl, ...props }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <SectionTitle>{props.sectionTitle}</SectionTitle>
        <SectionDescription>{props.sectionSubTitle}</SectionDescription>
      </TextWrapper>
      <CardsWrapper>
        {props.cardData.map((item, index) => {
          return (
            <HomeCardComponent
              key={index}
              image={item.node.image.childImageSharp.gatsbyImageData}
              title={intl.formatMessage({ id: item.node.title })}
              text={intl.formatMessage({ id: item.node.text })}
              some={intl.formatMessage({ id: item.node.some })}
              to={item.node.path}
            />
          )
        })}
      </CardsWrapper>
    </Wrapper>
  )
}

export default injectIntl(HomeCardsSectionComponent)

const Wrapper = styled.div``

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
  margin-top: 30px;
  flex-wrap: wrap;

  @media screen and (min-width: ${breakpoint.xsm}) {
    flex-direction: row;
    margin-top: 50px;
    justify-content: space-between;
  }
`

const TextWrapper = styled.div`
  width: 100%;
`
