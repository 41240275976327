import React from 'react'
import styled from 'styled-components'
import { breakpoint, color, transition } from '../../../configs/utilities'
import { Link } from 'gatsby-plugin-react-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SectionText } from '../typography/Typography'
import ButtonLink from '../buttons/ButtonLink'

const HomeMainCardComponent = (props) => {
  return (
    <Wrapper>
      <TitleWrapper>{props.title}</TitleWrapper>

      <SectionTextWrapper>
        <SectionText>{props.text}</SectionText>
      </SectionTextWrapper>

      <Link to={props.to}>
        <ImageWrapper> {props.image && <GatsbyImage image={props.image} alt={props.text} />}</ImageWrapper>
      </Link>

      <ButtonWrapper>
        <ButtonLink to={props.to} buttonText={props.some} />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default HomeMainCardComponent

const Wrapper = styled.div`
  color: ${color.black};
  background: ${color.white};

  @media screen and (min-width: ${breakpoint.md}) {
    width: 48%;
  }

  @media screen and (min-width: ${breakpoint.lg}) {
    width: 48%;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  margin: 20px 0 0;

  img {
    transition: transform 0.25s, visibility 0.25s ease-in-out;
    @media screen and (min-width: ${breakpoint.xl}) {
      transition: all 0.5s ease-in-out;
    }
  }
  @media (hover: hover) {
    &:hover {
      transform: scale(1.025);
      transition: all 0.5s ease-in-out;
      filter: grayscale(0);
      img {
        transition: all 0.5s ease-in-out;
      }
    }
  }
`

const TitleWrapper = styled.p`
  font-family: 'Playfair Display', serif;
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-weight: 500;
  font-size: 28px;
  line-height: 18px;
  color: ${color.black};
  transition: ${transition.default};
`

const SectionTextWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
    min-height: 170px;
  }
  @media screen and (min-width: ${breakpoint.lg}) {
    min-height: 110px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 170px;
  }
`
