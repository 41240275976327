import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../configs/utilities'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useWindowSize } from '../../configs/getGlobalWindowSize'
import HeroTitle from '../../components/common/typography/HeroTitle'

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      heroXl: file(relativePath: { eq: "home/hero/heroXl.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1200]
          )
        }
      }
      heroSm: file(relativePath: { eq: "home/hero/heroSm.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1000
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1200]
          )
        }
      }
    }
  `)

  const currentWidth = useWindowSize().width
  return (
    <SectionWrapper>
      <Wrapper>
        <GatsbyImage
          image={
            currentWidth > 992
              ? data.heroXl.childImageSharp.gatsbyImageData
              : data.heroSm.childImageSharp.gatsbyImageData
          }
          alt="hero image"
        />
        <HeroTitle />
      </Wrapper>
    </SectionWrapper>
  )
}

export default Hero

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 0;
  row-gap: 75px;

  @media screen and (min-width: ${breakpoint.md}) {
    min-height: 90vh;
    row-gap: 0;
    justify-content: space-between;
    padding: 50px 0 100px;

  }

  @media screen and (min-width: ${breakpoint.xl}) {
  }

  @media screen and (min-width: ${breakpoint.huge}) {
    min-height: auto;
    padding: 150px 0 200px;
    row-gap: 100px;
  }
`
