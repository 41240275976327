import React from 'react'
import styled from 'styled-components'
import { color, breakpoint, transition } from '../../../configs/utilities'
import { Link } from 'gatsby-plugin-react-intl'

const ButtonLink = (props) => {
  return props.external ? (
    <ExternalLink href={props.to} target="_blank" rel="noopener noreferrer" download={props.download}>
      <ButtonStyle 
      buttonBackground={props.buttonBackground}
      buttonTextColor={props.buttonTextColor}
      >{props.buttonText}
      </ButtonStyle>
    </ExternalLink>
  ) : (
    <InternalLink to={props.to}>
     <ButtonStyle 
      buttonBackground={props.buttonBackground}
      buttonTextColor={props.buttonTextColor}
      >{props.buttonText}
      </ButtonStyle>
    </InternalLink>
  )
}

export default ButtonLink

const InternalLink = styled(Link)``

const ExternalLink = styled.a``

const ButtonStyle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: ${(props) => props.buttonBackground || color.white};
  color: ${(props) => props.buttonTextColor || color.black};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  transition: ${transition.default};
  max-width: 500px;
  margin: 0 auto;
  border: 0.5px solid ${color.black};
  &:hover {
    opacity: .5;
  }

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 5vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    padding: 12px 30px;
    font-size: 14px;
    min-width: 150px;
    max-width: 350px;
  }
`
