import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { breakpoint, color } from '../../../configs/utilities'

const HeroTitle = ({ intl }) => {
  return (
      <DesignWrapper>
          <DesignTextWrapper>
            <h1>Aleksandra Gočanin</h1>
          </DesignTextWrapper>
        <Portfolio>{intl.formatMessage({ id: 'home.hero.portfolio' })}</Portfolio>
      </DesignWrapper>
  )
}

export default injectIntl(HeroTitle)

const DesignWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: ${breakpoint.xl}) {
    width: 100%;
  }
`

const DesignTextWrapper = styled.div`
  h1 {
    font-weight: 300;
    font-size: 20px;
    color: ${color.black};
    margin-left: 5px;
 
    @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 5vw;
  }

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 25px;
    }

    @media screen and (min-width: ${breakpoint.xxl}) {
      font-size: 30px;
    }
  }
`

const Portfolio = styled.p`
  width: 100%;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 50px;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
  color: ${color.black};

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 12vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 70px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 100px;
  }
`
