import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import HomeMainCardsSectionComponent from '../../components/sections/HomeMainCardsSectionComponent'

const ProjectsSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allProjectsHomeCardsJson {
        edges {
          node {
            path
            title
            text
            some
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 750
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  breakpoints: [768, 1380]
                )
              }
            }
          }
        }
      }
    }
  `)
  const cardData = data.allProjectsHomeCardsJson.edges
  return (
    <SectionWrapper>
      <HomeMainCardsSectionComponent
      sectionTitle={intl.formatMessage({ id: 'home.projectsSection.title' })}
      sectionSubTitle={intl.formatMessage({ id: 'home.projectsSection.description' })}
      cardData={cardData}
      />
    </SectionWrapper>
  )
}

export default injectIntl(ProjectsSection)