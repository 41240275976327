import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { SectionTitle, SectionDescription, SectionText } from '../../components/common/typography/Typography'
import ButtonLink from '../../components/common/buttons/ButtonLink'
import { breakpoint } from '../../configs/utilities'

const WhoSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "home/who/who.webp" }) {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            breakpoints: [768, 1380]
          )
        }
      }
    }
  `)
  return (
    <SectionWrapper>
      <Wrapper>
        <ContentWrapper>
           <TitleWrapper>
              <SectionTitle>{intl.formatMessage({ id: 'home.whoSection.title' })}</SectionTitle>
              <SectionDescription>{intl.formatMessage({ id: 'home.whoSection.description' })}</SectionDescription>
           </TitleWrapper>
          <TextWrapper>
            <SectionText>{intl.formatMessage({ id: 'home.whoSection.text01' })}</SectionText>
            <SectionText>{intl.formatMessage({ id: 'home.whoSection.text02' })}</SectionText>
            <SectionText>{intl.formatMessage({ id: 'home.whoSection.text03' })}</SectionText>
            <SectionText>{intl.formatMessage({ id: 'home.whoSection.text04' })}</SectionText>
          </TextWrapper>
          <ButtonBig>
            <ButtonLink to="/contact/" buttonText={intl.formatMessage({ id: 'home.whoSection.contact' })} />
          </ButtonBig>
        </ContentWrapper>
        <ImageWrapper>
          <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="logo" />
        </ImageWrapper>
        <ButtonSmall>
          <ButtonLink to="/contact/" buttonText={intl.formatMessage({ id: 'home.whoSection.contact' })} />
        </ButtonSmall>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(WhoSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: ${breakpoint.md}) {
    width: 50%;
    max-width: 700px;
  }
`

const TitleWrapper = styled.div`
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   margin-bottom: 30px;
  @media screen and (min-width: ${breakpoint.xl}) {
   margin-bottom: 0;
     
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const ButtonBig = styled.div`
  width: 100%;
  display: none;
  @media screen and (min-width: ${breakpoint.md}) {
    display: flex;
  }
  `

const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    @media screen and (min-width: ${breakpoint.md}) {
      width: 50%;
      max-width: 400px;
      justify-content: flex-end;
    }
    `

const ButtonSmall = styled.div`
  width: 100%;
  margin-top: 20px;

  @media screen and (min-width: ${breakpoint.md}) {
    display: none;
  }
`


